import { ApiGatewayClient } from '../../utils/api-gateway-client.util';
import { appConfig } from '../../config';
import { ContentTypes } from '../../utils/enums/content-types.enum';
import { HttpMethod } from '../../utils/enums';
import { StorefrontModel } from '../../utils/interfaces/storefront-model.interface';

/**
 * Class responsible for manage the API call and data request of the Request View
 */
export class RequestService {
    private client: ApiGatewayClient;
    private headers = {
        'Content-Type': ContentTypes.json,
        Accept: ContentTypes.json,
    };
    /**
     * Instantiates a new identified ApiGatewayClient
     */
    constructor() {
        this.client = new ApiGatewayClient();
    }

    /**
     * This method sends retrieved info by the AutocreditMxUsed to the storefront
     * @param itemId this id is from the Mercado Libre publication, where we will look for the vehicle information
     */
    public async sendStoreFrontData(itemId: string): Promise<StorefrontModel> {
        const url = `${appConfig.apiUrl}/${itemId}`;
        const request = {
            url,
            method: HttpMethod.post,
            headers: this.headers,
        };

        const retrivedData = await this.client.sendRequest<StorefrontModel>(
            request,
        );
        const result = retrivedData.data;

        return result;
    }
}
