import React from 'react';

import { appConfig } from '../../config';

/**
 * Page view to refer that the request was not processed successfully
 */
export const ErrorPage: React.FC = () => {
    return (
        <div className='App'>
            <header className='c-header__wrapper'>
                <div className='c-header c-header--static'>
                    <div className='c-header__content-wrapper'>
                        <div className='c-header__logo'>
                            <a
                                href='https://www.vwfs.mx/'
                                className='c-logo'
                                title='HOME'
                            >
                                <img className='c-logo__image' alt='' />
                            </a>
                        </div>
                    </div>
                </div>
            </header>
            <main className='o-main  '>
                <section className='o-content-section  '>
                    <div className='o-page-wrap  '>
                        <header className='c-section-heading '>
                            <h1 className='c-section-heading__title'>
                                <span className='c-section-heading__title-text'>
                                    Ha ocurrido un error
                                </span>
                            </h1>

                            <p className='c-section-heading__subtitle'>
                                Por favor, continua con tu preevaluación de
                                crédito dando clic{' '}
                                <a href={appConfig.preapprovalUrl}>aquí</a>.
                                <br />
                                Disculpa las molestias
                            </p>
                        </header>
                    </div>
                </section>
            </main>
        </div>
    );
};
