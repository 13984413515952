import axios, { AxiosResponse } from 'axios';

import { HttpRequestParams } from './interfaces';

/**
 * Class responsible for making aws api gateway requeststo mercado-libre API gateway
 */
export class ApiGatewayClient {
    /**
     * This method send and http request to the external API
     * @param requestData required params to send an Axios http call
     */
    public sendRequest<T>(
        requestData: HttpRequestParams,
    ): Promise<AxiosResponse<T>> {
        return axios.request(requestData);
    }
}
