import { Route, HashRouter as Router, Switch } from 'react-router-dom';

import { ErrorPage } from './views/ErrorPage';
import { Request } from './views/Request';

const App = () => (
    <Router>
        <Switch>
            <Route path='/failed-request' component={ErrorPage} />
            <Route path='/:itemId?' component={Request} />
        </Switch>
    </Router>
);

export default App;
